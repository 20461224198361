import { Button, Checkbox, Form, Input } from "semantic-ui-react";

function WhatsappDetails({
  submitting,
  whatsapp,
  onChange,
  onSubmit,
  onClickBack,
}) {
  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>Is this a WhatsApp order</label>
          <Checkbox
            toggle
            name="required"
            checked={whatsapp.required}
            value={whatsapp.required}
            onChange={(e, f) => {
              onChange(e, { name: f.name, value: f.checked });
            }}
          />
        </Form.Field>
        {whatsapp.required && (
          <Form.Field>
            <label>Quantity of WhatsApp licenses</label>
            <Input
              type="number"
              name="quantity"
              onChange={onChange}
              value={whatsapp.quantity}
            />
          </Form.Field>
        )}

        <Button.Group>
          <Button onClick={onClickBack}>Back</Button>
          <Button.Or />
          <Button loading={submitting} primary>
            Next
          </Button>
        </Button.Group>
      </Form>
    </>
  );
}

export default WhatsappDetails;

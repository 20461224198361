import { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  FormButton,
  FormGroup,
  Header,
  Input,
  Menu,
  MenuItem,
  Popup,
} from "semantic-ui-react";
import { storageKeys } from "../constants";
import { useAcd } from "../hooks/ipecs/useAcd";
import AcdAvailabilityDropdown from "./AcdAvailabilityDropdown";

const AcdActions = () => {
  const [open, setOpen] = useState(false);
  const [agentId, setAgentId] = useState(
    JSON.parse(localStorage.getItem(storageKeys.ACD_AGENT_ID))?.id || "",
  );
  const [editingAgentId, setEditingAgentId] = useState(!agentId || false);

  const {
    acdListQueues,
    acdGetStatus,
    acdSetStatus,
    acdGetCompanies,
    acdLogon,
    acdAgentIdentifications,
    acdLogoff,
  } = useAcd();
  const { data: queues, isFetching: queuesFetching } = acdListQueues;
  const { data: acdStatus, isFetching: statusFetching } = acdGetStatus;
  const {
    data: acdCompanies,
    isFetching: companiesFetching,
    refetch: companiesRefetch,
  } = acdGetCompanies;

  const isQueueActive = (groupId) => {
    return acdStatus?.logonGroupIds.includes(groupId);
  };

  const handleAction = async (params) => {
    const _params = {
      agentId: agentId,
      groupId: params.groupId,
      groupNumber: params.groupNumber,
      acdLoginType: params.acdLoginType,
    };
    if (isQueueActive(params.groupId)) {
      acdLogoff.mutate(_params);
    } else {
      companiesRefetch();
      acdLogon.mutate(_params);
    }
  };

  const handleSetStatus = (status) => {
    acdSetStatus.mutate(status);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "ready":
        return "green";
      case "work":
        return "yellow";
      case "not_ready":
        return "red";
      default:
        return "grey";
    }
  };

  const handleEditAgentId = () => {
    acdLogoff.mutate({
      agentId: agentId,
      groupId: "",
      groupNumber: "",
      acdLoginType: "all",
    });
    setEditingAgentId(true);
    setAgentId("");
    localStorage.removeItem(storageKeys.ACD_AGENT_ID);
  };

  const handleSetAgentId = (id) => {
    setAgentId(id);
    localStorage.setItem(storageKeys.ACD_AGENT_ID, JSON.stringify({ id: id }));
  };

  const isLoading = () => {
    return (
      queuesFetching ||
      statusFetching ||
      acdSetStatus.isLoading ||
      acdLogon.isLoading
    );
  };

  return (
    <Popup
      style={{ backgroundColor: "transparent", border: "none" }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      open={open}
      trigger={
        <div>
          <Button
            icon={"headphones"}
            color={getStatusColor(acdStatus?.mode)}
            basic
            onClick={() => setOpen(true)}
          />
        </div>
      }
      content={
        <Menu vertical size="huge" inverted>
          <MenuItem>
            <Header>ACD settings</Header>
            {agentId && !editingAgentId ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div>Agent ID: {agentId}</div>
                  <div>
                    <Button
                      icon={"edit outline"}
                      compact
                      size="tiny"
                      color="teal"
                      onClick={handleEditAgentId}
                    />
                  </div>
                </div>
                {acdStatus?.mode !== "logoff" && (
                  <div>
                    Status:{" "}
                    <AcdAvailabilityDropdown
                      status={acdStatus?.mode}
                      cause={acdStatus?.causeCode}
                      handleSetStatus={handleSetStatus}
                      disabled={companiesFetching}
                      causeCodes={
                        acdCompanies?.tenants[0]?.agentNotreadyCauseInfo
                      }
                    />
                  </div>
                )}
              </div>
            ) : (
              <Form>
                <FormGroup>
                  <Form.Field>
                    <Input
                      style={{ width: "100px" }}
                      placeholder="Agent ID"
                      disabled={isLoading()}
                      value={agentId}
                      onChange={(_, { value }) => handleSetAgentId(value)}
                    />
                  </Form.Field>
                  <FormButton
                    disabled={isLoading()}
                    loading={isLoading()}
                    onClick={() => {
                      setEditingAgentId(false);
                      acdAgentIdentifications.mutate(agentId);
                    }}
                    color="teal"
                  >
                    Log on
                  </FormButton>
                </FormGroup>
              </Form>
            )}
          </MenuItem>
          {agentId && !editingAgentId && queues?.length > 0 && (
            <MenuItem>
              <Header>Queues</Header>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                {queues
                  ?.sort((a, b) => a.group.groupNumber - b.group.groupNumber)
                  ?.map((queue) => (
                    <div
                      key={queue.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "5px",
                      }}
                    >
                      <span>
                        {queue.group.groupNumber} - {queue.group.groupName}
                      </span>
                      <Checkbox
                        toggle
                        checked={isQueueActive(queue.groupId)}
                        disabled={isLoading()}
                        onChange={() =>
                          handleAction({
                            acdLoginType: queue.acdLoginType,
                            groupId: queue.groupId,
                            groupNumber: queue.group.groupNumber,
                          })
                        }
                      />
                    </div>
                  ))}
              </div>
            </MenuItem>
          )}
        </Menu>
      }
      on="click"
      position="bottom left"
    />
  );
};

export default AcdActions;

import { Icon } from "semantic-ui-react";
import useContactLookup from "../hooks/ContactLookup";
import { useEffect } from "react";

function ContactDisplayName({
  name,
  number,
  internal = false,
  autoOpenCrmLink = false,
}) {
  const { contact } = useContactLookup(number);

  useEffect(() => {
    if (!internal) {
      if (contact && contact.web_link && autoOpenCrmLink) {
        window.open(contact.web_link, "_blank");
      }
    }
  }, [autoOpenCrmLink, contact, internal]);

  if (contact) {
    return (
      <span
        style={{
          overflowWrap: "break-word",
          wordWrap: "break-word",
          msWordBreak: "break-all",
          wordBreak: " break-word",
          hyphens: "auto",
        }}
      >
        {contact.web_link && (
          <Icon
            link
            onClick={() => {
              window.open(contact.web_link, "_blank");
            }}
            name="external alternate"
          />
        )}
        {contact.first_name} {contact.last_name} ({number})
      </span>
    );
  }

  return <span>{name}</span>;
}

export default ContactDisplayName;

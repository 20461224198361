import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  agentIdentifications,
  getCompanies,
  getStatus,
  listQueues,
  logoff,
  logon,
  setStatus,
} from "../../api/ipecs/acd";
import { useAuth } from "../../contexts/AuthContext";

export const useAcd = () => {
  const { apiUser, ipecsAuthData } = useAuth();
  const queryClient = useQueryClient();

  const acdLogon = useMutation({
    mutationFn: ({ acdLoginType, agentId, groupId, groupNumber }) => {
      return logon({
        acdLoginType: acdLoginType,
        agentId: agentId,
        authToken: ipecsAuthData.authInfo.accessToken,
        groupId: groupId,
        groupNumber: groupNumber,
        serverUrl: apiUser.server.url,
        userId: ipecsAuthData.authInfo.userId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["acdQueues"]);
      queryClient.invalidateQueries(["acdStatus"]);
    },
    onError: (error) => {
      console.log("Unable to log on to queue");
    },
  });

  const acdLogoff = useMutation({
    mutationFn: ({ acdLoginType, agentId, groupId, groupNumber }) => {
      return logoff({
        agentId: agentId,
        authToken: ipecsAuthData.authInfo.accessToken,
        groupId: groupId,
        groupNumber: groupNumber,
        serverUrl: apiUser.server.url,
        type: acdLoginType,
        userId: ipecsAuthData.authInfo.userId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["acdQueues"]);
      queryClient.invalidateQueries(["acdStatus"]);
    },
    onError: (error) => {
      console.log("Unable to log off of queue");
    },
  });

  const acdListQueues = useQuery({
    queryKey: ["acdQueues"],
    queryFn: () =>
      listQueues({
        authToken: ipecsAuthData.authInfo.accessToken,
        serverUrl: apiUser.server.url,
      }),
    select: (data) => data.data.data,
  });

  const acdSetStatus = useMutation({
    mutationFn: (status) => {
      return setStatus({
        authToken: ipecsAuthData.authInfo.accessToken,
        serverUrl: apiUser.server.url,
        userId: ipecsAuthData.authInfo.userId,
        mode: status.mode,
        causeCode: status.causeCode,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["acdQueues"]);
      queryClient.invalidateQueries(["acdStatus"]);
    },
    onError: (error) => {
      console.log("Unable to set status");
    },
  });

  const acdAgentIdentifications = useMutation({
    mutationFn: (agentId) => {
      return agentIdentifications({
        authToken: ipecsAuthData.authInfo.accessToken,
        serverUrl: apiUser.server.url,
        agentId: agentId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["acdQueues"]);
      queryClient.invalidateQueries(["acdStatus"]);
    },
    onError: (error) => {
      console.log("Unable to activate");
    },
  });

  const acdGetStatus = useQuery({
    queryKey: ["acdStatus"],
    queryFn: () =>
      getStatus({
        authToken: ipecsAuthData.authInfo.accessToken,
        serverUrl: apiUser.server.url,
        userId: ipecsAuthData.authInfo.userId,
      }),
    select: (data) => data.data,
    refetchInterval: 60000,
  });

  const acdGetCompanies = useQuery({
    queryKey: ["acdCompanies"],
    queryFn: () =>
      getCompanies({
        authToken: ipecsAuthData.authInfo.accessToken,
        serverUrl: apiUser.server.url,
        companyId: ipecsAuthData.authInfo.companyId,
      }),
    select: (data) => data.data,
    enabled: false,
  });

  return {
    acdAgentIdentifications,
    acdGetCompanies,
    acdGetStatus,
    acdListQueues,
    acdLogoff,
    acdLogon,
    acdSetStatus,
  };
};

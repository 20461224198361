import axios from "axios";

export function logon({
  acdLoginType,
  agentId,
  authToken,
  groupId,
  groupNumber,
  serverUrl,
  userId,
}) {
  return axios.post(
    `${serverUrl}/apis/pim/pbxes/phoneUsers/${encodeURIComponent(
      userId,
    )}/acd/logon`,
    {
      agentId: agentId,
      groupId: groupId,
      groupNumber: groupNumber,
      type: acdLoginType,
    },
    {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}

export function logoff({
  agentId,
  authToken,
  groupId,
  groupNumber,
  serverUrl,
  type,
  userId,
}) {
  return axios.post(
    `${serverUrl}/apis/pim/pbxes/phoneUsers/${encodeURIComponent(
      userId,
    )}/acd/logoff`,
    {
      agentId: agentId,
      groupId: groupId,
      groupNumber: groupNumber,
      type: type,
    },
    {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}

export async function listQueues({ authToken, serverUrl }) {
  return await axios.get(`${serverUrl}/apis/wuc/userAcds`, {
    params: {
      includeModels: "group",
    },
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
}

export function setStatus({ authToken, causeCode, mode, serverUrl, userId }) {
  return axios.put(
    `${serverUrl}/apis/pim/pbxes/phoneUsers/${encodeURIComponent(
      userId,
    )}/acd/status`,
    {
      causeCode: causeCode,
      mode: mode,
    },
    {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}

export function getStatus({ authToken, serverUrl, userId }) {
  return axios.get(
    `${serverUrl}/apis/pim/pbxes/phoneUsers/${encodeURIComponent(
      userId,
    )}/acd/status`,
    {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}

export function agentIdentifications({ authToken, agentId, serverUrl }) {
  return axios.get(
    `${serverUrl}/apis/wuc/acdAgentIdentifications?preconditionAttributes=acdAgentLoginId%3A${agentId}`,
    {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}

export function getCompanies({ authToken, companyId, serverUrl }) {
  return axios.get(
    `${serverUrl}/apis/wuc/companies/${companyId}?includeModels=tenant`,
    {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
}

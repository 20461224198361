import { useMemo } from "react";
import { Dropdown, Icon } from "semantic-ui-react";

const defaultStatusOptions = [
  {
    key: "ready",
    text: "Available",
    value: "ready",
    color: "green",
  },
  {
    key: "work",
    text: "Wrap-up",
    value: "work",
    color: "yellow",
  },
  {
    key: "not_ready",
    text: "Unavailable",
    value: "not_ready",
    color: "red",
  },
];

const processValue = (value) => {
  if (value.startsWith("cause_")) {
    return {
      mode: "not_ready",
      causeCode: value.slice(6),
    };
  }
  return {
    mode: value,
    causeCode: "",
  };
};

const AcdAvailabilityDropdown = ({
  cause = "",
  causeCodes = [],
  disabled,
  handleSetStatus,
  status = "ready",
}) => {
  const statusOptions = useMemo(() => {
    const causeOptions = causeCodes?.map(({ code, str }) => ({
      key: `cause_${code}`,
      text: `${str}`,
      value: `cause_${code}`,
      color: "red",
    }));
    return [...defaultStatusOptions, ...causeOptions];
  }, [causeCodes]);

  let selectedOption;
  if (cause) {
    selectedOption =
      statusOptions.find((option) => option.key === `cause_${cause}`) ||
      statusOptions[2];
  } else {
    selectedOption =
      statusOptions.find((option) => option.key === status) || statusOptions[2];
  }

  return (
    <span>
      <Icon name="circle" color={selectedOption?.color || "grey"} />
      <Dropdown
        disabled={disabled}
        inline
        options={statusOptions}
        defaultValue={selectedOption.value}
        onChange={(e, { value }) => {
          handleSetStatus(processValue(value));
        }}
      />
    </span>
  );
};

export default AcdAvailabilityDropdown;
